<template>
  <div>
    <div class="has-background-white has-shadow pa-md">
      <p class="has-text-weight-bold is-size-6 has-text-centered">Billing</p>
      <table class="table is-fullwidth is-really-narrow is-size-8 is-paddingless is-striped">
        <tbody>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Scheduled</th>
            <td class="has-text-right">
              <Currency :value="project.amount_scheduled" :mantissa="0" />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Billed</th>
            <td class="has-text-right">
              <Currency :value="project.amount_billed" :mantissa="0" />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Remaining</th>
            <td class="has-text-right">
              <Currency :value="Number(planOnlyFunding) - Number(project.amount_billed)" :mantissa="0" />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Prebill Balance</th>
            <td class="has-text-right">
              <Currency :value="Number(project.amount_billed) - Number(project.amount_fulfilled) - Number(project.amount_fulfilled)" :mantissa="0" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    planOnlyBudget () {
      let total = 0
      this.project.positions.forEach(budget => {
        if (budget.billing_model === 'time') {
          total += Number(budget.amount_budgeted)
        }
      })
      return total
    },
    planOnlyFunding () {
      let total = 0
      this.project.positions.forEach(budget => {
        if (budget.billing_model === 'time') {
          total += Number(budget.amount_funded)
        }
      })
      return total
    },
  },
  data () {
    return {
      showHelp: false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
